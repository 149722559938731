import React from "react";
import { useQuery } from "@tanstack/react-query";
import { AutoComplete, Button } from "dms-lib";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import PersonIcon from "@mui/icons-material/Person";
import { useI18n } from "compass-commons";

import { RequestKey } from "../../../utils/Requests";
import { OnDemandIncidentFormDTO } from "../../../models/onDemandIncident/onDemandIncidentForm";
import { OptionUser, UserDTO } from "../../../models/users/UserDTO";
import { UserResponseDTO } from "../../../models/users/UserResponseDTO";
import { useOnDemandContext } from "../OnDemandContext";
import UserManagerService from "../../../services/UserManagerService";

interface OperatorAssignProps {
  control: Control<OnDemandIncidentFormDTO>;
  setFieldValue: UseFormSetValue<OnDemandIncidentFormDTO>;
  watch: UseFormWatch<OnDemandIncidentFormDTO>;
  inputLabel?: string;
  enabled?: boolean;
  currentUser: UserDTO;
}

const OperatorAssign = ({
  control,
  setFieldValue,
  watch,
  inputLabel,
  currentUser,
  enabled,
}: OperatorAssignProps): JSX.Element => {
  const { t: translate } = useI18n();
  const { incidentQueuesEnabled } = useOnDemandContext();
  const selectedQueue = watch("incidentQueue");

  const isEnabled = Boolean(
    !incidentQueuesEnabled || enabled || selectedQueue?.id
  );

  const { data, isLoading } = useQuery({
    enabled: isEnabled,
    placeholderData: { users: [] } as UserResponseDTO,
    queryKey: [RequestKey.OnlineOperators, selectedQueue?.id],
    queryFn: () => UserManagerService.loadOnlineOperators(selectedQueue?.id),
  });

  const DEFAULT_OPERATOR: OptionUser = {
    id: null,
    userId: null,
    email: null,
    firstName: "",
    lastName: "",
    nameInitials: "",
    name: translate(
      isDMS
        ? "ondemand.operatorAssignAutomaticOption"
        : "ondemand.operatorAssignNoOneOption"
    ),
  };

  const handleAssignToMe = async () => {
    setFieldValue("assign", {
      id: currentUser.userId,
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      email: currentUser.email,
    });
  };

  const options = (() => {
    const users = (data?.users || []).map((user) => ({
      ...user,
      id: user.userId,
      name: `${user.firstName} ${user.lastName}`,
    }));
    return [DEFAULT_OPERATOR, ...users];
  })();

  const canSelfAssign = (() => {
    if (!currentUser) return false;

    return incidentQueuesEnabled
      ? isEnabled && options.find(({ id }) => id === currentUser.userId)
      : true;
  })();

  return (
    <Controller
      name="assign"
      control={control}
      render={({ field: { onChange, value, ...field } }) => {
        return (
          <>
            <div>
              <div className="ondemand-form-section-label-spacing">
                {inputLabel || translate("ondemand.operatorAssignInputLabel")}
              </div>
              <AutoComplete<OptionUser>
                {...field}
                value={value as OptionUser}
                options={options}
                loading={isLoading}
                dataCr="incident-selector-operator"
                getOptionLabel={(option) => option.name}
                onChangeCallback={(operator) =>
                  onChange(operator || DEFAULT_OPERATOR)
                }
                disabled={!isEnabled}
                IconComponent={({ value: { id } }) =>
                  id === DEFAULT_OPERATOR.id && <PersonIcon />
                }
              />
            </div>
            {canSelfAssign && (
              <Button
                variant="text"
                color="primary"
                onClick={handleAssignToMe}
                size="small"
                className="ondemand-form-self-assign-button"
                dataCr="incident-selector-self-assign"
                disabled={
                  watch("assign.email") === currentUser.email &&
                  currentUser.email !== null
                }
              >
                {translate("ondemand.assignToMeButton")}
              </Button>
            )}
          </>
        );
      }}
    />
  );
};

export default OperatorAssign;
