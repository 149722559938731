import { QueryClient } from "@tanstack/react-query";

export enum RequestKey {
  IncidentQueues = "IncidentQueues",
  OnlineOperators = "OnlineOperators",
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: appConfig.ENV.includes("dev") ? 0 : 3,
    },
  },
});

export const getUrlSearchParams = (
  data: Record<string, any>
): URLSearchParams => {
  const isEmpty = (val: any) => val === null || val === undefined;
  return new URLSearchParams(
    Object.fromEntries(Object.entries(data).filter(([_, val]) => !isEmpty(val)))
  );
};
