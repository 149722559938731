import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useGetToken, useI18n, useFeatureFlag } from "compass-commons";
import { Dialog } from "dms-lib";
import "./supervisorDashboard.module.css";
import { getOrgIdFromToken } from "compass-shared-services";
import ResultTable from "./dynamicResultTable/ResultTable";
import OIMService from "../../services/OIMService";
import IncidentClearRequestError from "../../errors/IncidentClearRequestError";
import ClearIncidentDialogActions from "./dialog/clearIncidentDialogActions";
import ClearIncidentDialogContent from "./dialog/clearIncidentDialogContent";
import { useGlobalContext } from "../../contexts/GlobalContext";
import { PagedOperationIncidentListDTO } from "../../models/supervisorDashboard/PagedOperationIncidentListDTO";
import { ClearIncidentRequestDTO } from "../../models/supervisorDashboard/ClearIncidentRequestDTO";
import { OperationIncidentInfo } from "../../models/notification/OperationIncidentInfo";
import { useBooleanSearchParamState } from "../../hooks/UseSearchParamsState";
import {
  AUTOMATIC_UPDATE_FEATURE_FLAG,
  AUTOMATIC_UPDATE_PARAM,
  INCIDENTS_AUTOMATIC_UPDATE_TIMER,
} from "../../utils/Constants";
// Hooks
import { useActivations } from "../../hooks/useActivations";
import { useDashboardDynamicFields } from "../../hooks/useDashboardDynamicFields";
import { EActivationsState } from "../../models/supervisorDashboard/IncidentAdditionalActivation";
import { FetchIncidentsRequestDTO } from "../../models/supervisorDashboard/FetchIncidentsRequestDTO";

const { OPERATION_INCIDENT_INFO_HUB } = appConfig;

const AUTOMATIC_UPDATE_DEFAULT_VALUE = true;

const SupervisorDashboard: React.FC = (): JSX.Element => {
  const { t: translate } = useI18n();
  // FEATURE FLAGS
  const token = isStandalone ? localStorage.getItem("token") : useGetToken();
  const orgId = getOrgIdFromToken(token);
  const { enabled: automaticFeatureEnabled } = useFeatureFlag(
    appConfig,
    AUTOMATIC_UPDATE_FEATURE_FLAG
  );

  // Used to update the searchParams
  const [automaticSearchParam, setAutomaticSearchParam] =
    useBooleanSearchParamState(
      AUTOMATIC_UPDATE_PARAM,
      AUTOMATIC_UPDATE_DEFAULT_VALUE.toString()
    );

  // Create a media file Polling for each image
  // Purpose: Check if image is still being proccessed
  // Only trigger setTimeout if image is still being prepared
  const incidentsAutomaticUpdateTimeout = useRef(null);

  const [openDialog, setOpenDialog] = useState(false);
  const ref = useRef(null);
  const globalContext = useGlobalContext();
  const { stateService, listenerService } = globalContext;
  const [clearIncidentComment, setClearIncidentComment] = useState("");
  const fetchIdRef = React.useRef(0);
  const [loading, setLoading] = useState(true);
  const openedIncidentActivationsRef = useRef(null);
  const { alertSubject, pageChangeSubject, clearIncidentHolder } = stateService;
  const [clearIncidentDetails, setClearIncidentDetails] =
    useState<ClearIncidentRequestDTO>();

  const { resetOperationIncidentListMap, updateOperationIncidentListMap } =
    useDashboardDynamicFields(stateService.operationIncidentMap);
  const { getActivationsDetails } = useActivations({
    incidentActivationsParam: stateService.incidentActivationsDetails,
  });
  const { updateOperationIncidentItem } = useDashboardDynamicFields(
    stateService.operationIncidentMap
  );

  // Used to check by the setTimeout if new events arrived
  const hasIncidentsToFetch = useRef(false);
  const lastFetchIncidentsTime = useRef(Date.now());
  let previousLatestTriggeredTime: number = Date.now();

  const updateIncidentsInfo = (info?: OperationIncidentInfo) => {
    stateService?.operationIncidentInfo?.next(info || null);
    hasIncidentsToFetch.current = !!info;
  };

  const refresh = (isBadgeClicked = false) => {
    resetOperationIncidentListMap();
    // Update the latest triggered time on refresh to showcase the latest incidents
    stateService.latestTriggeredTimeByResult.next(previousLatestTriggeredTime);
    previousLatestTriggeredTime = Date.now();
    // Refresh into first page
    pageChangeSubject.next(0);
    updateIncidentsInfo();
    // eslint-disable-next-line
    if (isBadgeClicked) resetIncidentsAutomaticUpdateTimer();
  };

  useEffect(() => {
    const sub = clearIncidentHolder.subscribe(setClearIncidentDetails);
    return () => sub.unsubscribe();
  }, [clearIncidentHolder]);

  useEffect(() => {
    listenerService.listen(
      OPERATION_INCIDENT_INFO_HUB,
      (msg) => {
        const info: OperationIncidentInfo = msg;
        if (
          openedIncidentActivationsRef.current === info.id &&
          !info.isCleared
        ) {
          getActivationsDetails(
            info.id,
            info.siteId,
            info.siteName,
            info.activationsCount,
            EActivationsState.SOCKET_ACTIVATION
          );
        }

        // This below is temporary solution until OperationIncidentInfo is updated
        const nameParts = info.assignedToUserFullName?.split(" ") || [];
        const firstName = nameParts[0];
        const lastName = nameParts.length > 1 ? nameParts[1] : "";
        const firstNameInitial = firstName?.[0]?.toUpperCase() || "";
        const lastNameInitial = lastName?.[0]?.toUpperCase() || "";

        updateOperationIncidentItem(info.id, {
          assignedUserDTO: {
            userId: info.assignedToUserId,
            firstName,
            lastName,
            email: null,
            nameInitials: firstNameInitial + lastNameInitial,
          },
          additionalActivationsCount: info.activationsCount,
          disabled: info.isCleared,
        });

        if (lastFetchIncidentsTime.current > info.lastUpdatedDate) {
          return;
        }

        updateIncidentsInfo(info);
      },
      "operation-incident-info",
      () => {
        refresh(true);
      }
    );

    return function cleanup() {
      listenerService.stopListening(
        OPERATION_INCIDENT_INFO_HUB,
        "operation-incident-info"
      );
    };
  }, [listenerService]);

  /**
   * Get incidents
   * By default it is ordered by timestamp and priority
   * @param fetchIncidentsRequest
   */
  const getIncidents = async (
    fetchIncidentsRequest: FetchIncidentsRequestDTO
  ): Promise<PagedOperationIncidentListDTO> => {
    let searchResultDTO: PagedOperationIncidentListDTO = null;
    try {
      searchResultDTO = await OIMService.getIncidentList(fetchIncidentsRequest);
    } catch (error) {
      alertSubject.next({ title: translate("supervisor.clearedError") });
    }
    return searchResultDTO;
  };

  const fetchIncidents = (fetchIncidentsRequest: FetchIncidentsRequestDTO) => {
    setLoading(true);
    getIncidents(fetchIncidentsRequest)
      .then((response: PagedOperationIncidentListDTO) => {
        if (response) {
          updateOperationIncidentListMap(
            response.operationIncidentList,
            response.totalPages,
            response.totalRecords
          );
          lastFetchIncidentsTime.current = response.lastUpdatedDate;
        } else {
          updateOperationIncidentListMap([], 0, 0);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const clearIncident = async (
    clearIncidentRequest?: ClearIncidentRequestDTO
  ) => {
    if (clearIncidentRequest?.incidentId) {
      return OIMService.clearIncident(clearIncidentRequest).then(() => {
        alertSubject.next({
          title: translate("supervisor.clearedSuccess"),
          severity: "success",
        });
      });
    }

    return OIMService.clearAllIncidents(clearIncidentRequest).then(
      (response) => {
        let message = "";
        if (response === 200) {
          message = translate("supervisor.clearedSuccess");
        } else {
          message = translate("supervisor.clearedEnqueuedSuccess");
        }
        alertSubject.next({
          title: message,
          severity: "success",
        });
      }
    );
  };

  const incidentsAutomaticUpdate = () => {
    if (hasIncidentsToFetch.current) refresh();
    incidentsAutomaticUpdateTimeout.current = setTimeout(
      incidentsAutomaticUpdate,
      INCIDENTS_AUTOMATIC_UPDATE_TIMER
    );
  };

  const updateAutomaticRefreshCheckbox = (value) => {
    setAutomaticSearchParam(value);
  };

  const incidentsAutomaticUpdateTimerState = useCallback((val) => {
    if (val) {
      incidentsAutomaticUpdate();
    } else clearInterval(incidentsAutomaticUpdateTimeout?.current);

    updateAutomaticRefreshCheckbox(val);
  }, []);

  const resetIncidentsAutomaticUpdateTimer = () => {
    // Only updates if the automatic refresh is active and enabled
    if (!automaticFeatureEnabled || !automaticSearchParam) return;
    incidentsAutomaticUpdateTimerState(false);
    incidentsAutomaticUpdateTimerState(true);
  };

  const clearIncidentCallBack = (
    clearIncidentRequest: ClearIncidentRequestDTO
  ) => {
    setLoading(true);
    clearIncident(clearIncidentRequest)
      .then(() => {
        refresh();
      })
      .catch((error) => {
        setLoading(false);
        alertSubject.next({ title: translate("supervisor.clearedError") });
        throw new IncidentClearRequestError(error);
      });
  };

  const closeDeleteModal = () => {
    setOpenDialog(!openDialog);
  };

  const confirmDelete = () => {
    let clearIncidentRequest = clearIncidentDetails;
    clearIncidentRequest = {
      comment: clearIncidentComment,
      incidentId: clearIncidentRequest.incidentId,
      timeStamp: clearIncidentRequest.timeStamp,
      executionId: clearIncidentRequest.executionId,
      incidentQueueId: clearIncidentRequest.incidentQueueId,
    };
    clearIncidentCallBack(clearIncidentRequest);
    closeDeleteModal();
  };

  const commentCallbackHandler = useCallback((comment) => {
    setClearIncidentComment(comment);
  }, []);

  /**
   * Fetch Data callback is used as callback method for table.
   */
  const fetchDataCallback = useCallback(
    (fetchIncidentsRequest: FetchIncidentsRequestDTO) => {
      // Give this fetch an ID
      // eslint-disable-next-line no-plusplus
      const fetchId = ++fetchIdRef.current;

      // Set the loading state

      if (fetchId === fetchIdRef.current) {
        fetchIncidents(fetchIncidentsRequest);
      }
    },
    []
  );

  const resultTableCallbackHandler = useCallback(
    (clear: ClearIncidentRequestDTO) => {
      setOpenDialog(!openDialog);
      clearIncidentHolder.next(clear);
    },
    [openDialog]
  );

  const badgeClickedCallback = useCallback(() => {
    refresh(true);
  }, []);

  const manualAssignCallback = useCallback(() => {
    refresh(true);
  }, []);

  const openedIncidentActivationsCallback = useCallback((incidentId) => {
    openedIncidentActivationsRef.current = incidentId;
  }, []);

  const automaticRefreshFeature = useMemo(
    () => ({
      enabled: automaticFeatureEnabled,
      value: automaticSearchParam,
      handleAutomaticRefresh: incidentsAutomaticUpdateTimerState,
    }),
    [
      automaticFeatureEnabled,
      automaticSearchParam,
      incidentsAutomaticUpdateTimerState,
    ]
  );

  useEffect(() => {
    if (typeof automaticFeatureEnabled !== "boolean")
      alertSubject.next({
        title: translate("supervisor.failedFetchFeatureFlagAutomaticUpdate"),
      });
    if (automaticFeatureEnabled && automaticSearchParam)
      incidentsAutomaticUpdate();
  }, [automaticFeatureEnabled]);

  useEffect(() => {
    refresh(true);
  }, [orgId]);

  return (
    <>
      {openDialog && (
        <Dialog
          dataCr="delete-clear-incident-modal"
          handleClose={closeDeleteModal}
          open={openDialog}
          dialogContent={
            <ClearIncidentDialogContent
              commentCallback={commentCallbackHandler}
              clearIncident={clearIncidentDetails}
            />
          }
          dialogActions={
            <ClearIncidentDialogActions
              onCancel={closeDeleteModal}
              onSuccess={confirmDelete}
              approvalConditionsMet={!!clearIncidentComment}
            />
          }
        />
      )}
      <div
        data-cr="supervisor-incident-list"
        className="dashboards__results-panel-main-div compass-fade-in-smooth"
      >
        <div ref={ref} className="dashboards__results-panel">
          <ResultTable
            resultTableCallback={resultTableCallbackHandler}
            fetchData={fetchDataCallback}
            loading={loading}
            operationIncidentInfoSubject={stateService?.operationIncidentInfo}
            automaticRefreshFeature={automaticRefreshFeature}
            badgeClickCallback={badgeClickedCallback}
            manualAssignCallback={manualAssignCallback}
            openedIncidentActivationsCallback={
              openedIncidentActivationsCallback
            }
          />
        </div>
      </div>
    </>
  );
};

export default SupervisorDashboard;
