import React, { useCallback, useState } from "react";
import { useI18n } from "compass-commons";

import { useGlobalContext } from "../../../../../contexts/GlobalContext";
import { IncidentQueueDTO } from "../../../../../models/incidentQueues/IncidentQueueDTO";
import IncidentQueueSelector from "../../../../incidentQueue/incidentQueueSelector";
import "./selectIncidentQueue.scss";

export const STORED_SELECTED_QUEUE_KEY = `${appConfig.NAMESPACE}_selectedIncidentQueue`;

const SelectIncidentQueue = (): JSX.Element => {
  const [selectedIncidentQueue, setSelectedIncidentQueue] =
    useState<IncidentQueueDTO>(null);

  const { stateService } = useGlobalContext();
  const { selectedIncidentQueueSubject, alertSubject } = stateService;
  const { t: translate } = useI18n();

  const onChangeCallback = (incidentQueue: IncidentQueueDTO) => {
    setSelectedIncidentQueue(incidentQueue);
    localStorage.setItem(STORED_SELECTED_QUEUE_KEY, incidentQueue.id);

    if (incidentQueue) {
      selectedIncidentQueueSubject.next({
        id: incidentQueue.id,
      });
    }
  };

  const handleFetchError = useCallback(() => {
    selectedIncidentQueueSubject.next({ id: null });
    alertSubject.next({
      title: translate("supervisor.incidentQueues.failedFetchIncidentQueues"),
    });
  }, [selectedIncidentQueueSubject, alertSubject, translate]);

  const handleFetchSuccess = useCallback(
    (data: IncidentQueueDTO[]) => {
      if (selectedIncidentQueue) return;
      if (!data.length) {
        alertSubject.next({
          title: translate("supervisor.incidentQueues.noIncidentQueuesError"),
        });
        selectedIncidentQueueSubject.next({ id: null });
        return;
      }

      const oldSelection = localStorage.getItem(STORED_SELECTED_QUEUE_KEY);
      const selection = data.find((d) => d.id === oldSelection) || data[0];
      onChangeCallback(selection);
    },
    [
      selectedIncidentQueue,
      selectedIncidentQueueSubject,
      alertSubject,
      translate,
    ]
  );

  return (
    <div
      className="dashboards__table-select-incident-queue"
      data-cr="dashboards-table-select-incident-queue"
    >
      <IncidentQueueSelector
        disableClearable
        disableLinearLoad
        showIcon
        dataCr="incident-queue-auto-complete"
        value={selectedIncidentQueue}
        onChangeCallback={onChangeCallback}
        onFetchError={handleFetchError}
        onFetchSuccess={handleFetchSuccess}
      />
    </div>
  );
};

export default SelectIncidentQueue;
