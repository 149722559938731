import {
  LocalizationNS,
  useFeatureFlag,
  useGetToken,
  useI18n,
} from "compass-commons";
import { AuthWrapper } from "dms-lib";
import React, { useEffect, useState } from "react";
import { ON_DEMAND_INCIDENT_FEATURE_FLAG } from "../../utils/Constants";
import OnDemandIncident from "./OnDemandIncident";
import "./onDemandIncident.scss";
import OnDemandProvider from "./OnDemandContext";

const OnDemandIncidentWrapper = (): JSX.Element => {
  const { t: translate } = useI18n();

  const token = isStandalone ? localStorage.getItem("token") : useGetToken();

  const { isFeatureEnabled } = useFeatureFlag(
    appConfig,
    ON_DEMAND_INCIDENT_FEATURE_FLAG,
    undefined,
    { skipAutoRequest: true }
  );

  const [isAllowedUser, setIsAllowedUser] = useState<boolean>();
  useEffect(() => {
    isFeatureEnabled(ON_DEMAND_INCIDENT_FEATURE_FLAG)
      .then((enabled) => setIsAllowedUser(!!enabled))
      .catch(() => setIsAllowedUser(false));
  }, [token]);

  return (
    <div className="ondemand-page">
      <AuthWrapper
        isAuthorized={isAllowedUser}
        isLoading={isAllowedUser === undefined}
        unauthorizedTitle={translate("unauthorized", {
          ns: LocalizationNS.SHARED,
        })}
        unauthorizedDescription={translate("unauthorizedContact", {
          ns: LocalizationNS.SHARED,
        })}
      >
        <OnDemandProvider>
          {isAllowedUser && <OnDemandIncident />}
        </OnDemandProvider>
      </AuthWrapper>
    </div>
  );
};

export default OnDemandIncidentWrapper;
