import {
  AllowedModules,
  CompassAuth,
  HttpClient,
} from "compass-shared-services";

import { UserResponseDTO } from "../models/users/UserResponseDTO";
import { UserDTO } from "../models/users/UserDTO";
import { getUrlSearchParams } from "../utils/Requests";

const USER_MANAGER_URL = appConfig.USER_MANAGER_SERVICE_PATH;
const httpClient = new HttpClient(appConfig);

const URL_PATHS = {
  GET_USER_USERGROUPS: `${USER_MANAGER_URL}/manager/user-groups/logged-user`,
  GET_ONLINE_OPERATORS: `${USER_MANAGER_URL}/manager/users?userStatus=IN_OPERATION&pageSize=100`,
  GET_USER: `${USER_MANAGER_URL}/manager/users-by-email`,
};

const compassAuth = CompassAuth(appConfig.SERVER_URL, appConfig.NAMESPACE);

class UserManagerService {
  static async isUserAllowed(
    allowedModule = AllowedModules.DASHBOARD
  ): Promise<boolean> {
    try {
      return await compassAuth.isUserAllowed(
        URL_PATHS.GET_USER_USERGROUPS,
        allowedModule
      );
    } catch (error) {
      return false;
    }
  }

  static async loadOnlineOperators(
    incidentQueueId: string
  ): Promise<UserResponseDTO> {
    const config = { params: getUrlSearchParams({ incidentQueueId }) };

    return httpClient
      .get<UserResponseDTO>({ url: URL_PATHS.GET_ONLINE_OPERATORS, config })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  static async getUserByEmail(email: string): Promise<UserDTO> {
    return httpClient
      .get<UserResponseDTO>({
        url: URL_PATHS.GET_USER,
        config: {
          params: {
            email,
          },
        },
      })
      .then((response) => {
        return response.users[0];
      })
      .catch((error) => {
        throw Error(error);
      });
  }
}

export default UserManagerService;
