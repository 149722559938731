import React, { useMemo, useState } from "react";
import { useI18n } from "compass-commons";
// Material UI
import MenuIcon from "@mui/icons-material/Menu";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// Project modules
import "./incidentMenu.module.css";
import { Menu, MenuItem, Spinner } from "dms-lib";
import { OperationIncidentDTO } from "../../../models/supervisorDashboard/OperationIncidentDTO";
import UserManagerService from "../../../services/UserManagerService";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { UserDTO } from "../../../models/users/UserDTO";
import OIMService from "../../../services/OIMService";
import { openOperationTabIfClosed } from "../../../utils/TabOpenUtils";

interface IncidentMenuProps {
  incident: OperationIncidentDTO;
  selectedIncidentId: string;
  clearIncidentCallback: () => void;
  refreshTableCallback: () => void;
  onOpen?: () => void;
  currentUser: UserDTO;
}

const IncidentMenu = ({
  incident,
  selectedIncidentId,
  clearIncidentCallback,
  refreshTableCallback,
  onOpen,
  currentUser,
}: IncidentMenuProps): JSX.Element => {
  const { t: translate } = useI18n();
  const globalContext = useGlobalContext();
  const { alertSubject, assignIncidentUser, selectedIncidentQueueSubject } =
    globalContext.stateService;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [operatorsMenuAnchorEl, setOperatorsMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [isOperatorsMenuOpen, setIsOperatorsMenuOpen] = useState(false);
  const [onlineOperators, setOnlineOperators] = useState<UserDTO[]>([]);
  const [isLoadingOnlineOperators, setIsLoadingOnlineOperators] =
    useState(false);
  const getIconButtonClass = useMemo(
    () => (isMenuOpen ? "active" : "inactive"),
    [isMenuOpen]
  );
  const isSelectedIncident = selectedIncidentId === incident.id;

  const closeAllMenus = () => {
    setAnchorEl(null);
    setOperatorsMenuAnchorEl(null);
    setIsOperatorsMenuOpen(false);
    setIsMenuOpen(false);
  };

  if (isMenuOpen && !isSelectedIncident) {
    closeAllMenus();
  }

  const openMenu = (event: React.MouseEvent<any>) => {
    event.stopPropagation();
    if (!isMenuOpen) {
      setAnchorEl(event.currentTarget);
      setIsMenuOpen(true);
    } else if (isSelectedIncident) {
      setAnchorEl(null);
      setIsMenuOpen(false);
    }
  };

  const closeMenu = (event?) => {
    event?.stopPropagation();
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const loadOnlineOperators = () => {
    setIsLoadingOnlineOperators(true);
    UserManagerService.loadOnlineOperators(
      selectedIncidentQueueSubject.value.id
    )
      .then((response) => {
        const onlineOperatorsArray = [];
        onlineOperatorsArray.push(...response.users);
        setOnlineOperators(onlineOperatorsArray);
        setIsLoadingOnlineOperators(false);
      })
      .catch(() => {
        setIsLoadingOnlineOperators(false);
        alertSubject.next({
          title: `${translate(
            "supervisor.assignIncident.failedFetchOnlineOperators"
          )}`,
        });
      });
  };

  const openOperatorsMenu = (event: React.MouseEvent<any>) => {
    event?.stopPropagation();
    setOperatorsMenuAnchorEl(event.currentTarget.parentElement);
    setIsOperatorsMenuOpen(true);
    loadOnlineOperators();
  };

  const closeOperatorsMenu = (event?) => {
    event?.stopPropagation();
    setOperatorsMenuAnchorEl(null);
    setIsOperatorsMenuOpen(false);
  };

  const assignIncidentToUser = (operator: UserDTO) => {
    const serviceCall = incident.assignedUserDTO?.userId
      ? OIMService.reAssignIncidentToUser(
          incident.id,
          incident.assignedUserDTO?.userId,
          operator.userId
        )
      : OIMService.assignIncidentToUser(incident.id, operator.userId);

    if (operator.userId === currentUser?.userId) {
      assignIncidentUser.next(incident.id);
    }

    serviceCall.then(refreshTableCallback).catch((error) => {
      const errorResponse = JSON.parse(error.message);
      const failedToAssignIncidentMessage = translate(
        "supervisor.assignIncident.failedToAssign"
      );
      alertSubject.next({
        title: `${failedToAssignIncidentMessage} ${
          errorResponse.data.errorCode === 611
            ? `- ${translate("incidents.notAvailableForAssignment")}`
            : ""
        }`,
      });
    });
    if (currentUser && currentUser?.userId === operator?.userId) {
      openOperationTabIfClosed();
    }
    closeAllMenus();
  };

  const unAssignIncident = (userId: string) => {
    OIMService.unAssignIncident(incident.id, userId)
      .then(refreshTableCallback)
      .catch((error) => {
        const errorResponse = JSON.parse(error.message);
        const failedToAssignIncidentMessage = translate(
          "supervisor.assignIncident.failedToAssign"
        );
        alertSubject.next({
          title: `${failedToAssignIncidentMessage} ${
            errorResponse.data.errorCode === 611
              ? `- ${translate("incidents.notAvailableForAssignment")}`
              : ""
          }`,
        });
      });

    closeAllMenus();
  };

  const getOperatorLabel = (operatorList: UserDTO[], operator: UserDTO) => {
    if (
      operator?.userId === currentUser?.userId &&
      incident.assignedUserDTO?.userId !== null &&
      operatorList.length === 1
    ) {
      return translate("supervisor.assignIncident.noOperatorsAvailable");
    }

    if (operator?.userId === currentUser?.userId)
      return translate("supervisor.assignIncident.currentUser");

    return `${operator.firstName} ${operator.lastName}`;
  };

  const getAvailableOperators = (operators: UserDTO[]) => {
    const availableOperators = [];
    const renderMenuItem = (operator: UserDTO, index: number = null) => {
      const className =
        index !== operators.length - 1 ? "border-bottom" : "none";
      return (
        <MenuItem
          id={`operator-${operator.userId}`}
          key={operator.userId}
          onClick={(event) => {
            if (
              incident.assignedUserDTO?.userId !== null &&
              operators.length === 1
            ) {
              return;
            }

            event?.stopPropagation();
            assignIncidentToUser(operator);
          }}
          className={className}
        >
          {getOperatorLabel(operators, operator)}
        </MenuItem>
      );
    };

    if (operators.length > 1) {
      operators
        .filter(({ userId }) => userId !== incident?.assignedUserDTO?.userId)
        .map((operator, index) =>
          availableOperators.push(renderMenuItem(operator, index))
        );
    } else {
      const operator = operators[0];
      availableOperators.push(renderMenuItem(operator, 0));
    }

    return availableOperators;
  };

  return (
    <div data-cr="incident-menu" className="incident-menu-container-div">
      <MenuIcon
        aria-controls={isMenuOpen ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? "true" : undefined}
        onClick={
          ((event) => {
            onOpen?.();
            openMenu(event);
          }) || undefined
        }
        className={`incident-menu-icon ${
          isSelectedIncident
            ? `incident-menu-button-active ${getIconButtonClass}`
            : "incident-menu-button"
        }`}
      />
      <Menu
        open={isMenuOpen}
        onClose={closeMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          id="assign-incident-menu-item"
          onClick={openOperatorsMenu}
          className={isOperatorsMenuOpen ? "active" : "inactive"}
        >
          {translate("supervisor.assignIncident.assignIncidentToOperator")}
          <ArrowRightIcon />
        </MenuItem>
        {incident.assignedUserDTO?.userId && (
          <MenuItem
            onClick={(event?) => {
              event?.stopPropagation();
              closeMenu();
              unAssignIncident(incident.assignedUserDTO?.userId);
            }}
            id="unassign-incident-menu-item"
          >
            {translate("supervisor.assignIncident.unAssign")}
          </MenuItem>
        )}
        <MenuItem
          onClick={(event?) => {
            event?.stopPropagation();
            closeMenu();
            clearIncidentCallback();
          }}
          disabled={incident.assignedUserDTO?.userId !== null}
          id="clear-incident-menu-item"
        >
          {translate("supervisor.clearButton")}
        </MenuItem>
      </Menu>
      <Menu
        open={isOperatorsMenuOpen}
        onClose={closeOperatorsMenu}
        anchorEl={operatorsMenuAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: -5,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="operators-sub-menu"
      >
        {isLoadingOnlineOperators && (
          <MenuItem className="spinner-menu-item">
            <Spinner />
          </MenuItem>
        )}
        {!isLoadingOnlineOperators &&
          onlineOperators.length > 0 &&
          getAvailableOperators(onlineOperators)}
        {!isLoadingOnlineOperators && onlineOperators.length === 0 && (
          <MenuItem disabled>
            {translate("supervisor.assignIncident.noOperatorsFound")}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

IncidentMenu.defaultProps = {
  onOpen: undefined,
};

export default IncidentMenu;
