import { OperationIncidentDTO } from "../models/supervisorDashboard/OperationIncidentDTO";

function getUserFullName(incident: OperationIncidentDTO): string {
  if (!incident) return "";

  return `${incident.assignedUserDTO?.firstName || ""} ${
    incident.assignedUserDTO?.lastName || ""
  }`.trim();
}

// eslint-disable-next-line import/prefer-default-export
export { getUserFullName };
