import { string, object, z, number } from "zod";

export const OnDemandIncidentFormDTO = object({
  site: object({ id: string().min(1), name: string().min(1) }).nullable(),
  floorplan: object({ id: number(), name: string() }).optional().nullable(),
  description: string().optional(),
  behavior: object({ id: string(), name: string() }).optional().nullable(),
  incidentQueue: object({
    icon: string().nullable(),
    id: string().nullable(),
    name: string(),
  })
    .optional()
    .nullable(),
  assign: object({
    id: string().nullable(),
    name: string(),
    email: string().nullable(),
  })
    .optional()
    .nullable(),
});

export type OnDemandIncidentFormDTO = z.infer<typeof OnDemandIncidentFormDTO>;

export interface OnDemandIncidentFormSubmit {
  siteId: string;
  floorPlanId: number | null;
  description: string | null;
  ruleId: string;
  assignToUserId: string | null;
  incidentQueueId: string | null;
}
