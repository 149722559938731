/* eslint-disable */
export const COMMENTS_MAXIMUM_CHARACTERS = 256;

// URL PARAMS
export const AUTOMATIC_UPDATE_PARAM = "automaticUpdate";

// DASHBOARD
// Equivelent to 10 seconds
export const INCIDENTS_AUTOMATIC_UPDATE_TIMER = 10000;
export const DEFAULT_DEBOUNCE_TIMEOUT = 500;

// INCIDENT QUEUE
export const INCIDENT_QUEUE_PAGE_SIZE = 100;

// FEATURES KEYS
export const AUTOMATIC_UPDATE_FEATURE_FLAG = "d_dashboard-automatic-update";
export const SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG =
  "b_showGenericErrorMessages";
export const INCIDENT_QUEUES_FEATURE_FLAG = "d_incidentQueues";
export const ON_DEMAND_INCIDENT_FEATURE_FLAG = "d_ondemand";
