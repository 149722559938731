import React, { createContext, useMemo } from "react";
import { useFeatureFlag } from "compass-commons";

import { INCIDENT_QUEUES_FEATURE_FLAG } from "../../utils/Constants";

type OnDemandContextType = { incidentQueuesEnabled: boolean };
const OnDemandContext = createContext<OnDemandContextType>(undefined);

const OnDemandProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { enabled } = useFeatureFlag(appConfig, INCIDENT_QUEUES_FEATURE_FLAG);
  const context = useMemo(() => {
    return {
      incidentQueuesEnabled: enabled,
    };
  }, [enabled]);

  return (
    <OnDemandContext.Provider value={context}>
      {children}
    </OnDemandContext.Provider>
  );
};

const useOnDemandContext = (): OnDemandContextType => {
  const context = React.useContext(OnDemandContext);
  if (context === undefined) {
    throw new Error(
      "useOnDemandContext must be used within a OnDemandProvider"
    );
  }
  return context;
};

export { OnDemandProvider as default, useOnDemandContext };
