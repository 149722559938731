import React, { useState } from "react";
import { Button, Spinner } from "dms-lib";
import "./onDemandHeader.scss";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormSetError,
  UseFormWatch,
} from "react-hook-form";
import { LocalizationNS, useI18n } from "compass-commons";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  OnDemandIncidentFormDTO,
  OnDemandIncidentFormSubmit,
} from "../../../models/onDemandIncident/onDemandIncidentForm";
import OIMService from "../../../services/OIMService";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import FormCloseDialog from "../formCloseDialog/FormCloseDialog";
import { ROOTPATH } from "../../../router/Routes";
import FormReAssignDialog from "../formReAssignDialog/FormReAssignDialog";
import { openOperationTabIfClosed } from "../../../utils/TabOpenUtils";
import { UserDTO } from "../../../models/users/UserDTO";
import { AUTOMATIC_UPDATE_PARAM } from "../../../utils/Constants";
import { useOnDemandContext } from "../OnDemandContext";

interface OnDemandHeaderProps {
  watch: UseFormWatch<OnDemandIncidentFormDTO>;
  handleSubmit: UseFormHandleSubmit<OnDemandIncidentFormDTO>;
  setError: UseFormSetError<OnDemandIncidentFormDTO>;
  isSubmitting: boolean;
  isFormChanged: boolean;
  currentUser: UserDTO;
}

const OnDemandHeader = ({
  isFormChanged,
  watch,
  handleSubmit,
  isSubmitting,
  setError,
  currentUser,
}: OnDemandHeaderProps): JSX.Element => {
  const { t: translate } = useI18n();
  const navigate = useNavigate();
  const { incidentQueuesEnabled } = useOnDemandContext();
  const isSaveDisabled = watch(["site", "behavior"]).some((obj) => !obj?.id);
  const {
    stateService: { alertSubject },
  } = useGlobalContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [reAssignDialog, setReAssignDialog] = useState({
    open: false,
    incidentId: "",
    assignedToUserFullName: "",
  });

  const [searchParam] = useSearchParams();

  const handleNavigateBack = () => {
    navigate({
      pathname: ROOTPATH,
      search: `${AUTOMATIC_UPDATE_PARAM}=${
        searchParam.get(AUTOMATIC_UPDATE_PARAM) ?? true
      }`,
    });
  };

  const onSubmit: SubmitHandler<OnDemandIncidentFormDTO> = async (data) => {
    const formData: OnDemandIncidentFormSubmit = {
      siteId: data.site.id,
      floorPlanId: data.floorplan?.id || null,
      description: data.description,
      ruleId: data.behavior?.id,
      assignToUserId: data.assign?.id,
      ...(incidentQueuesEnabled && {
        incidentQueueId: data.incidentQueue.id,
      }),
    };

    try {
      const createdIncident = await OIMService.createOnDemandIncident(formData);
      alertSubject.next({
        title: translate("ondemand.incidentCreation.successMessage"),
        severity: "success",
      });

      // If assigned to me and tab is not open, we open it
      if (currentUser && currentUser?.userId === formData?.assignToUserId) {
        openOperationTabIfClosed();
      }
      // Validate if the incident was created and assigned to the same user
      if (
        !data.assign?.id ||
        createdIncident?.assignedToUserId === data.assign?.id
      ) {
        handleNavigateBack();
      } else {
        setReAssignDialog({
          open: true,
          incidentId: createdIncident?.id,
          assignedToUserFullName: data.assign?.name,
        });
      }
    } catch {
      alertSubject.next({
        title: translate("ondemand.incidentCreation.errorMessage"),
      });
      // Prevent form to be cleared after failed submission
      setError(null, { type: "custom" });
    }
  };

  const handleOpenDialog = () => {
    if (isFormChanged) {
      setOpenDialog(true);
    } else {
      handleNavigateBack();
    }
  };

  return (
    <div className="ondemand-header">
      <div className="ondemand-header-block">
        <Button
          variant="text"
          color="primary"
          icon
          onClick={handleOpenDialog}
          dataCr="ondemand-back-button"
        >
          <KeyboardBackspaceIcon />
        </Button>
        <div className="ondemand-header-block-title">
          {translate("ondemand.headerTitle")}
        </div>
      </div>
      <div className="ondemand-header-block">
        <Button
          variant="text"
          color="primary"
          onClick={handleOpenDialog}
          dataCr="ondemand-cancel-button"
        >
          {translate("cancel", { ns: LocalizationNS.SHARED })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isSaveDisabled || isSubmitting}
          onClick={handleSubmit(onSubmit)}
          dataCr="ondemand-save-button"
        >
          {isSubmitting ? (
            <Spinner size="small" />
          ) : (
            translate("save", { ns: LocalizationNS.SHARED })
          )}
        </Button>
      </div>
      <FormCloseDialog
        isOpen={openDialog}
        onConfirm={handleNavigateBack}
        onCancel={() => setOpenDialog(false)}
      />
      <FormReAssignDialog
        key={reAssignDialog.assignedToUserFullName}
        isOpen={reAssignDialog.open}
        incidentId={reAssignDialog.incidentId}
        prevAssignedUser={reAssignDialog.assignedToUserFullName}
        onCancel={handleNavigateBack}
        currentUser={currentUser}
      />
    </div>
  );
};

export default OnDemandHeader;
