import React, { useEffect, useRef, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import { debounce } from "@mui/material/utils";
import { useI18n } from "compass-commons";
import { AutoComplete, OptionType, ResourceIcon } from "dms-lib";

import { RequestKey } from "../../utils/Requests";
import { DEFAULT_DEBOUNCE_TIMEOUT } from "../../utils/Constants";
import { DEFAULT_INCIDENT_QUEUES_PAGE_SIZE } from "../../hooks/useActivations";
import { IncidentQueueDTO } from "../../models/incidentQueues/IncidentQueueDTO";
import OIMService from "../../services/OIMService";

type IncidentQueueOption = OptionType & { name: string; icon: string };
type AutoCompleteProps = React.ComponentProps<
  typeof AutoComplete<IncidentQueueOption>
>;

const IncidentQueueSelector = ({
  defaultOptions = [],
  showIcon = false,
  value,
  onFetchError,
  onFetchSuccess,
  ...props
}: Partial<AutoCompleteProps> & {
  defaultOptions?: IncidentQueueOption[];
  showIcon?: boolean;
  onFetchError?: (error: unknown) => void;
  onFetchSuccess?: (data: IncidentQueueDTO[]) => void;
}): JSX.Element => {
  const { t: translate } = useI18n();
  const [queryValue, setQueryValue] = useState("");
  const debouncedSetQuery = debounce(setQueryValue, DEFAULT_DEBOUNCE_TIMEOUT);
  const displayIconRef = useRef(true);

  const { data, error, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: [RequestKey.IncidentQueues, queryValue],
      queryFn: ({ pageParam = 0 }) => {
        return OIMService.getIncidentQueues({
          pageIndex: pageParam,
          pageSize: DEFAULT_INCIDENT_QUEUES_PAGE_SIZE,
          queryValue,
        });
      },
      getNextPageParam: (lastPage, pages) => {
        return lastPage.length ? pages.length : undefined;
      },
    });

  const handleInputChange = (
    _: React.SyntheticEvent<Element, Event>,
    val: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === "input") {
      debouncedSetQuery(val);
      displayIconRef.current = false;
    }
    if (reason === "reset") {
      setQueryValue("");
      displayIconRef.current = true;
    }
  };

  const options = (() => {
    const queues = data?.pages.flat() || [];
    return [...defaultOptions, ...queues];
  })();

  useEffect(() => {
    if (data) onFetchSuccess?.(data.pages.flat());
  }, [data]);

  useEffect(() => {
    if (error) onFetchError?.(error);
  }, [error]);

  return (
    <AutoComplete<IncidentQueueOption>
      value={value as IncidentQueueOption}
      getOptionLabel={(option) => option?.name || ""}
      loading={isLoading}
      loadingText={translate("loadingContent", { ns: "Shared" })}
      placeholder={translate("supervisor.incidentQueues.selectIncidentQueue")}
      options={options}
      onInputChange={handleInputChange}
      infiniteScroll={{
        pageLength: DEFAULT_INCIDENT_QUEUES_PAGE_SIZE,
        hasMore: hasNextPage,
        fetchNext: () => fetchNextPage(),
        isLoading,
      }}
      IconComponent={({ className, dataCr, value: val, isSelected }) => {
        if (!(showIcon && val?.icon)) return null;
        return displayIconRef.current || isSelected ? (
          <ResourceIcon data-cr={dataCr} className={className} src={val.icon} />
        ) : null;
      }}
      {...props}
    />
  );
};

export default IncidentQueueSelector;
